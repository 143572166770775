import React, {useContext, useState} from "react"
import { Row, Col, Spin, Layout } from 'antd'
import { Form, Input, Button, notification } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { conduktClient } from "clients/condukt"
import {AppContext} from "AppContextProvider";

const Login = () => {
    const [loading, setLoading] = useState(false)
    const { setJwt } = useContext(AppContext)

    const onFinish = async (data) => {
        setLoading(true)
        try {
            const res = await conduktClient.logIn(data.username, data.password)
            conduktClient.refreshToken = res?.access_token?.token_plaintext
            localStorage.setItem('refreshToken', res?.access_token?.token_plaintext)
            localStorage.setItem('username', res?.user_name)
            setJwt(await conduktClient.getJWTToken())

            window.location.reload()
        } catch (error) {
            notification.error({ message: "Unable to log in" })
        }
        setLoading(false)
    }

    return (
        <>
            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                <Row style={{ marginTop: "15%" }}>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <div style={{
                            display: "flex",
                            justifyContent: 'center',
                            padding: "10px",
                        }}>
                            <img style={{ maxWidth: "15rem" }} src="/condukt-logo-full.png" alt="" />
                        </div>
                        <br />
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Username required!' }]}
                            >
                                <Input data-testid="username" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Password required!' }]}
                            >
                                <Input
                                    data-testid="password"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    block
                                    htmlType="submit"
                                    type="primary"
                                    className="login-form-button"
                                    data-testid="login-button"
                                >
                                    {loading ? "Loading ..." : "Login"}
                                </Button>
                                <div className="centered" style={{ marginTop: "2%" }}>
                                    {/* Or <a href="/admin/register"> Register now!</a> */}
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </Layout>

        </>
    )
}

export default Login
