import React from 'react'
import { Table } from "antd"
import { addressToHumanReadable } from './utils';

const columns = [
    {
        dataIndex: 'property',
        key: 'property',
    },
    {
        dataIndex: 'value',
        key: 'value',
    },
];


const BeneficialOwners = ({ beneficialOwners }) => {
    if (!beneficialOwners || !beneficialOwners?.length) {
        return <div className='centered'>
            <p className='condukt-green'>No Beneficial Owners have been identified</p>
        </div>
    }

    const getOwnerData = (owner) => {
        const data = [
            {
                property: "Type",
                value: owner?.type
            },
            {
                property: "Name",
                value: owner?.name
            },
            {
                property: "Ownership",
                value: owner?.ownership_rights
            },
            {
                property: "Voting",
                value: owner?.voting_rights
            },
            {
                property: "Other Significant Control",
                value: owner?.has_other_significant_control ? 'True' : 'False'
            },
            {
                property: "Address",
                value: addressToHumanReadable(owner?.address)
            },
        ]

        if (owner?.type === 'NATURAL_PERSON') {
            data.push(
                {
                    property: "Birth Date",
                    value: owner?.date_of_birth

                },
                {
                    property: "Nationality",
                    value: owner?.nationality
                }
            )
        } else {
            data.push(
                {
                    property: "Registration Country",
                    value: owner?.registration_country
                },
                {
                    property: "Registration Number",
                    value: owner?.registration_number
                }
            )
        }

        return data
    }

    const getOwnerTable = (owner) => {
        return <>
            <Table
                showHeader={false}
                columns={columns}
                dataSource={getOwnerData(owner)}
                pagination={false}
                bordered
            />
            <br />
        </>
    }

    return (
        <>
            {
                beneficialOwners?.map(owner => {
                    return getOwnerTable(owner)
                })
            }
        </>
    )
}

export default BeneficialOwners
