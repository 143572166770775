import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { Row, Col, Tag, Table, notification, Skeleton, Button, Spin } from "antd"
import { conduktClient } from "clients/condukt"
import { AppContext } from "AppContextProvider";
import PDFReader from './PDFReader'

const Documents = () => {
    const [order, setOrder] = useState(null)
    const [documents, setDocuments] = useState([])
    const [isFetchingFirstTime, setIsFetchingFirstTime] = useState(true)
    const [orderGetInterval, setOrderGetInterval] = useState(null);

    const [selectedDocument, setSelectedDocument] = useState(null)
    const { selectedOrderId } = useContext(AppContext)

    const isStillFetching = useMemo(() => {
        return !['done','failed'].includes(order?.processing_state?.download_documents?.status)
    }, [order])

    const getOrder = useCallback(async (bpoOrderId) => {
        if (isFetchingFirstTime) {
            setIsFetchingFirstTime(false)
        }
        try {
            const order = await conduktClient.fetchBusinessProfileOrder(bpoOrderId)
            setOrder(order)
        } catch (error) {
            notification.error({ message: "Error occurred during order retrieval" })
        }
    }, [])


    useEffect(() => {
        setSelectedDocument(null)
        if (!selectedOrderId.bpoOrderId) {
            return
        }
        getOrder(selectedOrderId.bpoOrderId)
        
        const intervalId = setInterval(() => {
            getOrder(selectedOrderId.bpoOrderId);
        }, 3000);
        setOrderGetInterval(intervalId);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [selectedOrderId.bpoOrderId, getOrder])

    useEffect(() => {
         const sortDocuments = (documents) => {
        const order = ["INCORPORATION", "ARTICLES", "UBO_STATEMENT", "REGISTER_EXTRACT", "OTHER"];

        return documents.sort((a, b) => {
            const tag1 = a?.tags?.[0]?.toUpperCase()
            const tag2 = b?.tags?.[0]?.toUpperCase()

            let indexA = order.findIndex(e => e === tag1)
            let indexB = order.findIndex(e => e === tag2)

            if (indexA === -1) indexA = order.length;
            if (indexB === -1) indexB = order.length;

            return indexA - indexB;
        })
    }

    const removeDateFromDocuments = (documents) => {
        return documents.map(document => {
            if (document?.name === "Not found" || document?.name === "Pending") {
                return {
                    ...document,
                    date: ""
                }
            }
            return document
        })
    }
        const sorted_documents = sortDocuments(order?.profile?.documents || [])
        const documents = removeDateFromDocuments(sorted_documents)
        setDocuments(documents)

    }, [order]);



    const columns = [
        {
            title: 'Type',
            dataIndex: 'tags',
            key: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        return (
                            <Tag
                                style={{ color: "#ceff00", borderColor: "#ceff00" }}
                                key={tag}
                            >
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: "",
            dataIndex: 'id',
            render: (value, document) => {
                if (
                    document?.name === "Not found"
                ) {
                    return <>
                    </>
                }

                if (document?.name === "Pending" || isStillFetching) {
                  return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spin />
                    </div>
                  );
                }
                return <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{ minWidth: "6rem" }}
                            type={selectedDocument?.value === value ? "primary" : "default"}
                            onClick={() => {
                                if (selectedDocument?.value === value) {
                                    setSelectedDocument(null)
                                    return
                                }
                                
                                setSelectedDocument({
                                    label: document?.name,
                                    value
                                })
                            }}>
                            {
                                selectedDocument?.value === value
                                    ? "Selected"
                                    : "Select"
                            }
                        </Button>
                    </div>

                </>
            }
        },
    ]

    if (!isStillFetching) {
      clearInterval(orderGetInterval);
    }

    return (
        <>
            <Row gutter={12}>
                <Col span={24}>
                    <Table
                        id="documents-table"
                        loading={isFetchingFirstTime || documents.length === 0}
                        columns={columns}
                        dataSource={documents}
                        pagination={false}
                        locale={{
                            emptyText: <p></p>
                        }}
                    />
                    <br />
                    {
                        !!order?.profile?.documents?.length &&
                        <>
                            <PDFReader
                                documentId={selectedDocument?.value}
                                fileName={selectedDocument?.label}
                            />
                        </>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Documents
