import React from 'react'
import { Table } from "antd"
import { addressToHumanReadable } from "modules/business-profile/utils"

const columns = [
    {
        dataIndex: 'property',
        key: 'property',
    },
    {
        dataIndex: 'value',
        key: 'value',
    },
];

function getHumanReadableDelta(startDateStr, endDateStr) {
    const parseDate = (dateStr) => dateStr ? new Date(dateStr) : null;

    const registrationDate = parseDate(startDateStr);
    let cessationDate = endDateStr ? parseDate(endDateStr) : new Date(); // current date if cessationDate is null

    if (registrationDate) {
        let years = cessationDate.getFullYear() - registrationDate.getFullYear();
        let months = cessationDate.getMonth() - registrationDate.getMonth();
        let days = cessationDate.getDate() - registrationDate.getDate();

        if (days < 0) {
            months--;
        }
        if (months < 0) {
            years--;
            months += 12;
        }

        const delta = { years, months };

        const deltaHumanReadable = [];
        if (delta.years > 0) {
            deltaHumanReadable.push(`${delta.years} ${delta.years > 1 ? 'years' : 'year'}`);
        }
        if (delta.months > 0) {
            deltaHumanReadable.push(`${delta.months} ${delta.months > 1 ? 'months' : 'month'}`);
        }

        const prefixStr = endDateStr ? 'operated for' : 'active for';
        return deltaHumanReadable.length > 0 ? `${prefixStr} ${deltaHumanReadable.join(' ')}` : '';
    }

    return '';
}

const Company = ({ identity }) => {

    if (!identity) {
        return <div className='centered'>
            <p className='condukt-green'>No Company Information</p>
        </div>
    }

    const getRegistrationDate = (startDateStr, endDateStr) => {
        if (!startDateStr) return "N/A"

        const humanReadableDelta = getHumanReadableDelta(startDateStr, endDateStr)
        return `${startDateStr} (${humanReadableDelta})`
    }

    const getBusinessActivity = (businessActivity) => {
        if (!businessActivity) return "N/A"

        const codes = businessActivity?.codes?.map(code => `${code.code} - ${code.description}`);
        const codesStr = codes.join('; ');

        return `${businessActivity.classification} (${codesStr}) `
    }

    const data = [
        {
            property: "Name",
            value: identity?.name
        },
        {
            property: "Type",
            value: identity?.type
        },
        {
            property: "Registration Number",
            value: identity?.registration_number
        },
        {
            property: "Status",
            value: identity?.status
        },
        {
            property: "Country",
            value: identity?.country
        },
        {
            property: "Address",
            value: addressToHumanReadable(identity?.address)
        },
        {
            property: "Registration Date",
            value: getRegistrationDate(identity?.registration_date, identity?.cessation_date)
        },
        {
            property: "Industry Codes ",
            value: getBusinessActivity(identity?.business_activity)
        },
    ]

    return (
        <>
            <Table
                showHeader={false}
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
            />

        </>
    )
}

export default Company
