import React, { useContext, useState, useEffect, useCallback } from 'react'
import { notification, Skeleton} from "antd"
import { conduktClient } from "clients/condukt"
import { AppContext } from "AppContextProvider";

const UBO = () => {
    const [order, setOrder] = useState(null)
    const [isFetchingFirstTime, setIsFetchingFirstTime] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const { selectedOrderId } = useContext(AppContext)

    const getOrder = useCallback(async (uboOrder) => {
        if (isFetchingFirstTime) {
            setIsLoading(true)
            setIsFetchingFirstTime(false)
        }
        try {
            const ubo_order = await conduktClient.fetchUBOOrder(uboOrder)
            setOrder(ubo_order)
            if (ubo_order.ubo_data) {
                setIsLoading(false)
            }
        } catch (error) {
            notification.error({ message: "Error occurred during order retrieval" })
        }

    }, [isFetchingFirstTime])

    useEffect(() => {
        setIsLoading(true)
        if (!selectedOrderId.uboOrderId) {
            notification.error({ message: "UBO order not found" })
            return
        }

        const intervalCheck = setInterval(() => {
            getOrder(selectedOrderId.uboOrderId);
        }, 3000);

        return () => {
            if (intervalCheck) {
                clearInterval(intervalCheck);
            }
        };


    }, [selectedOrderId.uboOrderId, getOrder, setIsLoading])



    if (isLoading) {
        return (
            <>
                <h3>Fetching the UBO order...</h3>
                <Skeleton paragraph={{ rows: 20 }} active />
            </>
        )
    }
        const keysToFormat = [
        "ownership_through_shares",
        "ownership_through_shares_from",
        "ownership_through_shares_to",
        "ownership_through_voting_right",
        "ownership_through_voting_right_from",
        "ownership_through_voting_right_to"
    ];
    const ubo_data = order?.ubo_data
    // Convert all nested keys with value of  one of the keys in keysToFormat to a string
    let stack = [ubo_data];

    while (stack.length > 0) {
        let currentObj = stack.pop();

        for (let key in currentObj) {
            if (currentObj.hasOwnProperty(key)) {
                if (typeof currentObj[key] === 'object') {
                    stack.push(currentObj[key]);
                } else if (keysToFormat.includes(key) && typeof currentObj[key] === 'number') {
                    currentObj[key] = currentObj[key].toFixed(2);
                }
            }
        }
    }

    return (
        <>
            {/*Output the UBO json */}

                <h3>UBO Order</h3>
                <pre>{JSON.stringify(ubo_data, null, 2)}</pre>

        </>
    )
}

export default UBO
