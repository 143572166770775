import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Row, Col, Skeleton } from "antd"
import { AppContext } from 'AppContextProvider';
import { conduktClient } from "clients/condukt"
import Company from "./Company"
import BeneficialOwners from "./BeneficialOwners"
import Officers from "./Officers"
import { notification } from 'antd';

const BusinessProfile = () => {
    const [order, setOrder] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [orderGetInterval, setOrderGetInterval] = useState(null)

    const { selectedOrderId, debugMode } = useContext(AppContext)

    const getOrder = useCallback(async (selectedOrderId) => {
        setIsLoading(true)
        try {
            const order = await conduktClient.fetchBusinessProfileOrder(selectedOrderId)
            setOrder(order)
        } catch (error) {
            notification.error({ message: "Error occurred during order retrieval" })
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (!selectedOrderId.bpoOrderId) {
            return
        }
        getOrder(selectedOrderId.bpoOrderId)
        const intervalId = setInterval(() => {
            getOrder(selectedOrderId.bpoOrderId);
        }, 3000);

        setOrderGetInterval(intervalId);
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [selectedOrderId.bpoOrderId, getOrder, setOrderGetInterval])


    if (isLoading) {
        return (
            <>
                <h3>Fetching the order...</h3>
                <Skeleton paragraph={{ rows: 20 }} active />
            </>
        )
    }

    if (!["done", "failed"].includes(order?.processing_state?.fetch_company_identity?.status)) {
        return (
            <>
                <h3>Processing the order...</h3>
                <Skeleton paragraph={{ rows: 20 }} active />
            </>
        )
    }

    // if the order is either done or failed - stop retrying
    clearInterval(orderGetInterval)

    if (order?.processing_state?.fetch_company_identity?.status === "failed") {
        return <div className='centered'>
            <p className='condukt-green'>Order processing failed</p>
            {debugMode &&
    <>
        <h1>Debug Dump</h1>
        <textarea readOnly value={JSON.stringify(order, null, 2)} style={{width: '100%', height: '800px'}}/>
    </>
}
        </div>
    }


    const identity = order?.profile?.identity || {}
    const beneficialOwners = order?.profile?.identity?.beneficial_owners || []
    const officers = order?.profile?.identity?.officers || []

    return (
        <>
            <Row gutter={12}>
                <Col span={8}>
                    <div className='centered'>
                        <h2>Company</h2>
                    </div>
                    <Company
                        identity={identity}
                    />
                </Col>
                <Col span={8}>
                    <div className='centered'>
                        <h2 >
                            Beneficial Owners
                        </h2>
                    </div>

                    <BeneficialOwners
                        beneficialOwners={beneficialOwners}
                    />
                </Col>
                <Col span={8}>
                    <div className='centered'>
                        <h2>
                            Officers
                        </h2>
                    </div>
                    <Officers
                        officers={officers}
                    />
                </Col>
            </Row>
            {debugMode &&
    <>
        <h1>Debug Dump</h1>
        <textarea readOnly value={JSON.stringify(order, null, 2)} style={{width: '100%', height: '800px'}}/>
    </>
}
        </>
    )
}

export default BusinessProfile
